import { useEffect } from 'react';
import { LDEvaluationDetail } from 'launchdarkly-node-server-sdk';
import {
  AmplitudeEvent,
  setDeviceDataFromHeaders,
  setPagePropertiesFromData,
  trackAmplitudeEvent,
} from '@/lib/analyticsHelper';
import {
  LaunchDarklyEvaluationReasons,
  ScreenName,
  ScreenTemplate,
  SeoPageId,
  TealiumSlot,
  UI_FEATURE_FLAGS,
} from '@/constants';
import { CaregiverDetailPageProps } from '../caregiverDetailPageProps';

type ClientFeatureFlags = keyof typeof UI_FEATURE_FLAGS;
type FlagName = (typeof UI_FEATURE_FLAGS)[ClientFeatureFlags]['id'];

type AdditionalLDFlagsProp = {
  additionalLDFlags?: Partial<Record<FlagName, LDEvaluationDetail | undefined>>;
};

type AmplitudeTrackingProps<PropsType extends CaregiverDetailPageProps> = AdditionalLDFlagsProp & {
  deviceChar: PropsType['deviceChar'];
  testName: PropsType['testName'];
  serviceShortName: PropsType['serviceShortName'];
  hasCareCheck: boolean;
  hasAiBio: boolean;
};

const useAmplitudeTracking = <TData extends CaregiverDetailPageProps & AdditionalLDFlagsProp>({
  deviceChar,
  testName,
  hasCareCheck,
  serviceShortName,
  hasAiBio,
  additionalLDFlags,
}: AmplitudeTrackingProps<TData>) => {
  useEffect(() => {
    const amplitudeDataForProfileViewed = {
      caregiverType: hasCareCheck ? 'CareChecked' : undefined,
      has_ai_bio: hasAiBio,
    };
    if (testName) {
      const amplitudeDataForTestExposure = {
        test_name: testName,
        test_variant: '1',
        service_short_name: serviceShortName,
      };
      trackAmplitudeEvent(AmplitudeEvent.testExposure, amplitudeDataForTestExposure);
    }

    trackAmplitudeEvent(AmplitudeEvent.providerProfileViewed, amplitudeDataForProfileViewed);
  }, [testName, hasCareCheck, hasAiBio, serviceShortName]);

  useEffect(() => {
    if (typeof additionalLDFlags === 'object') {
      Object.entries(additionalLDFlags).forEach(([flagName, flagDetails]) => {
        if (flagDetails && flagDetails.reason.kind === LaunchDarklyEvaluationReasons.RULE_MATCH) {
          trackAmplitudeEvent(AmplitudeEvent.testExposure, {
            test_name: flagName,
            test_variant: flagDetails.variationIndex,
            service_short_name: serviceShortName,
          });
        }
      });
    }
  }, [additionalLDFlags, serviceShortName]);

  useEffect(() => {
    const pageProperties = {
      screen_name: ScreenName.PROVIDER,
      screen_template: ScreenTemplate.PROVIDER,
      seo_page_id: SeoPageId.PROVIDER,
      slots: TealiumSlot.VISITOR,
    };
    if (deviceChar && Object.keys(deviceChar).length > 0) {
      setPagePropertiesFromData(pageProperties);
      setDeviceDataFromHeaders(deviceChar);
    }
  }, [deviceChar]);
};

export default useAmplitudeTracking;
